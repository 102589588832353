import React, { useState, useContext } from 'react';

import StoreData from '../../data/store';
import { withCart } from '../Cart/context';
import { CheckoutContext } from './context';

import loadComponent from '../Loadable';

const Button = loadComponent('button-input');

export default withCart(function Discount({ total }) {
  const [code, setCode] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setDiscount, checkoutError, checkoutSuccess } =
    useContext(CheckoutContext);

  const handleChange = e => {
    setCode(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);
    checkoutError(false);

    StoreData.checkCoupon(code)
      .then(res => {
        const minimum = parseFloat(Math.round(res.minimum_amount * 100) / 100);
        const subTotal = parseFloat(Math.round(total * 100) / 100);
        if (!res.code) {
          checkoutError(
            'Coupon is invalid. Please ensure you are using all caps.',
          );
        } else if (subTotal > minimum) {
          setCoupon(true);
          setDiscount({
            id: res.id,
            code: res.code,
            amount: res.amount,
            type: res.discount_type,
            free_shipping: res.free_shipping,
          });
          checkoutSuccess(`Coupon has been applied to your order.`);
        } else {
          checkoutError(
            'You have not reached the minium order amount to use this coupon.',
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeDiscount = e => {
    e.preventDefault();
    setCoupon(false);
    setDiscount(false);
  };

  return (
    <form method="POST" onSubmit={handleSubmit} style={outerStyle}>
      <div className="couponField">
        <input
          type="text"
          name="code"
          id="code"
          title="Discount Code"
          placeholder="Discount Code"
          aria-label="Discount Code"
          onChange={handleChange}
          disabled={!!coupon}
          style={{ opacity: coupon && 0.6, pointerEvents: coupon && 'none' }}
          required
        />
      </div>
      <div className="couponField">
        {coupon ? (
          <Button
            value="Remove"
            onClick={removeDiscount}
            style={buttonStyle}
            primary
          />
        ) : (
          <Button
            value="Apply Coupon"
            disabled={!!(!code || loading)}
            className={!code || loading ? 'processing' : ''}
            processing={loading}
            style={{
              ...buttonStyle,
              backgroundImage: !code && 'none',
              backgroundPosition: '92% 50%',
            }}
            primary
          />
        )}
      </div>
    </form>
  );
});

const outerStyle = {
  display: `flex`,
  flexDirection: `column`,
  marginTop: `30px`,
};

const buttonStyle = {
  padding: `0.8125rem 1.875rem`,
  marginTop: `10px`,
};
